import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning-external-portal'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm) => {
    try {
        Store.commit('mutateCommonProperties', {
            loading: true
        })
        if (i18n.locale === 'bn') {
            pdfMake.vfs = pdfFontsBn.pdfMake.vfs
        } else {
            pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [{
            columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: reportHeadData.address, style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        pdfContent.push({ text: '', style: 'fertilizer' })
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['23%', '2%', '20%', '20%', '2%', '20%'],
                body: [
                    [
                        { text: vm.$t('portal.applicant_name'), style: 'ths', alignment: 'left' },
                        { text: ':', style: 'ths', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.name_en : data.name_bn, style: 'tds', alignment: 'left' },
                        { text: vm.$t('globalTrans.mobile'), style: 'ths', alignment: 'left' },
                        { text: ':', style: 'ths', alignment: 'center' },
                        { text: vm.$n('0') + vm.$n(data.mobile, { useGrouping: false }), style: 'tds', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('portal.transactionId'), style: 'ths', alignment: 'left' },
                        { text: ':', style: 'ths', alignment: 'center' },
                        { text: data.transaction_no, style: 'tds', alignment: 'left' },
                        { text: vm.$t('globalTrans.payment'), style: 'ths', alignment: 'left' },
                        { text: ':', style: 'ths', alignment: 'center' },
                        { text: vm.$n(data.fee_amount, { useGrouping: false }) + ' ' + vm.$t('globalTrans.tk'), style: 'tds', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('globalTrans.date'), style: 'ths', alignment: 'left' },
                        { text: ':', style: 'ths', alignment: 'center' },
                        { text: dateFormat(data.created_at), style: 'tds', alignment: 'left' },
                        { text: '', style: 'ths', alignment: 'left' },
                        { text: '', style: 'ths', alignment: 'left' },
                        { text: '', style: 'tds', alignment: 'left' }
                    ]
                ]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return 0
                },
                vLineWidth: function (i, node) {
                    return 0
                }
            }
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        var docDefinition = {
            content: pdfContent,
            pageSize: 'A4',
            pageOrientation: 'Portrait',
            watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
            styles: {
                ths: {
                    fillColor: '',
                    fontSize: (i18n === 'bn') ? 12 : 10,
                    // bold: true,
                    margin: [3, 10, 3, 2]
                },
                th: {
                    fillColor: '',
                    fontSize: (i18n === 'bn') ? 12 : 10,
                    // bold: true,
                    margin: [3, 2, 3, 2]
                },
                thh: {
                    fillColor: '',
                    fontSize: (i18n === 'bn') ? 12 : 10,
                    bold: true,
                    margin: [3, 2, 3, 2]
                },
                th1: {
                    fillColor: '#02813e',
                    color: 'white',
                    fontSize: (i18n === 'bn') ? 14 : 12,
                    // bold: true,
                    margin: [3, 3, 3, 3]
                },
                tds: {
                    fontSize: (i18n === 'bn') ? 12 : 10,
                    margin: [3, 10, 3, 3]
                },
                td: {
                    fontSize: (i18n === 'bn') ? 12 : 10,
                    margin: [3, 2, 3, 2]
                },
                sp1: {
                    fontSize: (i18n === 'bn') ? 12 : 10,
                    margin: [3, 3, 3, 8]
                },
                sp2: {
                    fontSize: (i18n === 'bn') ? 12 : 10,
                    margin: [3, 2, 3, 2]
                },
                sp3: {
                    fontSize: (i18n === 'bn') ? 12 : 10,
                    margin: [3, 8, 3, 8]
                },
                header: {
                  fontSize: 16,
                  bold: true,
                  margin: [0, 0, 0, 0]
              },
              org: {
                  fontSize: 13,
                  bold: true,
                  margin: [0, -25, 10, 5]
              },
              address: {
                  fontSize: 11,
                  margin: [0, 0, 0, 5]
              },
              hh: {
                  fontSize: 14,
                  bold: true,
                  margin: [10, 10, 25, 20]
              },
              fertilizer: {
                margin: [5, 0, 0, 5]
              },
                header2: {
                    fontSize: 15,
                    bold: true,
                    margin: [15, 15, 25, 10]
                },
                header11: {
                    fontSize: 13,
                    bold: true,
                    margin: [15, 10, 10, 10]
                },
                transport: {
                    fontSize: (i18n === 'bn') ? 12 : 10,
                    margin: [10, 10, 0, 20]
                },
                headerPort1: {
                    fontSize: (i18n === 'bn') ? 12 : 10,
                    margin: [0, 20, 0, 0]
                },
                headerPort: {
                    fontSize: (i18n === 'bn') ? 12 : 10,
                    margin: [0, 4, 0, 15]
                },
                header3: {
                    fontSize: 9,
                    margin: [0, 0, 0, 4]
                },
                tableSubHead: {
                    margin: [0, 5, 0, 15]
                },
                krishi: {
                    margin: [0, -15, 0, 15],
                    alignment: 'center'
                }
            }
        }
        pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
        if (error) {}
    }
    Store.commit('mutateCommonProperties', {
        loading: false
    })
}
export default {
    exportPdfDetails
}
