// Authentication and authorization
export const menuListApi = '/portal/publication/menu-list'
export const magazineListApi = '/portal/publication/magazine-list'
export const contentListApi = '/portal/publication/content-list'
export const userListApi = '/portal/publication/user-list'
export const venueContactInfoApi = '/portal/show'
// export const venueContactInfoApi = '/config/venue-condition/show'
export const duplicateCertificateApiPaymentSuccess = '/portal/payment-status/duplicate-certificate-request/' + 'success'
export const duplicateCertificateApiPaymentCancel = '/portal/payment-status/duplicate-certificate-request/' + 'cancel'
export const duplicateCertificateApiPaymentDecline = '/portal/payment-status/duplicate-certificate-request/' + 'decline'

// germplasm request api
export const getDataByRecieverCode = 'portal/germplasm/config/receiver-info/get-by-reciever-code/'
export const getDataByOrgId = 'portal/germplasm/config/organization/show/'
export const getAccessionInfo = 'portal/germplasm/check-status/get-details'

// germplasm collection api
export const germplasmRequest = 'portal/germplasm/requests'
export const germplasmRequestStore = germplasmRequest + '/store'
export const germplasmRequestUpdate = germplasmRequest + '/update'
