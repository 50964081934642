<template>
  <div class="main_wrapper">
    <div class="cover_image_wrapper">
      <h2 class="cover_title">{{ $t('portal.request_duplicate_certificate') }}</h2>
      <img src="../../../../assets/images/portal/cover-img.jpg" alt="" />
    </div>
    <div class="breadcrumb_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/portal/home">{{ $t('portal.home') }}</router-link></li>
                <li class="breadcrumb-item active"><a to="#">{{ $t('portal.request_duplicate_certificate') }}</a></li>
              </ol>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="menu_bar_wrapper">
      <b-container>
        <b-row>
          <b-col sm="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('portal.request_duplicate_certificate') }}</h4>
              </template>
              <template v-slot:body>
                <div class="all_service_wrapper venue">
                  <b-overlay :show="load">
                    <!-- <div>
                      <b-alert show dismissible variant="success" v-if="showMessage">
                        {{$t('globalTrans.we_notify_you_in_message')}} <b>&rArr;</b>
                      </b-alert>
                    </div> -->
                    <b-row>
                  <!-- <b-col>
                    <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail"
                      :org-id="orgiId">
                      <div class="titleDiv">
                      </div>
                    </list-report-head>
                  </b-col> -->
                </b-row>
                <b-col lg="12">
                <div>
                  <b-row>
                    <div class="card card w-100 text-center">
                      <div class="card-inside-title font-weight-bold text-capitalize p-1 text-black">
                        <h5 class="font-weight-bold text-capitalize text-center p-2"
                          style="color: green!important;font-size: 20px!important;">{{ $t('globalTrans.payment') }} {{ $t('globalTrans.success') }}</h5>
                      </div>
                    </div>
                  </b-row>
                  <div id="printData" class="bg-light">
                  <b-row>
                    <b-col class="mt-5">
                        <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                            <div class="titleDiv">
                              <!-- <span class="text-center text-dark reportTitle" style="font-weight:bold;"></span><br> -->
                            </div>
                        </list-report-head>
                    </b-col>
                  </b-row>
                    <b-row>
                      <b-col md="6" class="mb-2 text-center mx-auto mt-3">
                          <h5  style="font-weight:bold">{{ $t('globalTrans.payment') + ' ' + $t('globalTrans.receipt') }}</h5>
                      </b-col>
                    </b-row>
                    <b-row class="mb-3 mx-2 mt-5 ">
                      <table border="0" class="mt-3 tabletwo ml-5 mb-5" style="width:100%;">
                        <tbody>
                          <tr>
                            <td style="width: 18%"  class="text-left pl-3" ><b>{{ $t('portal.applicant_name') }}</b></td>
                            <td style="width: 2%"  class="text-left" ><b>{{ ':'}} </b></td>
                            <td style="width: 30%" class="text-left">{{ ($i18n.locale=='bn') ? profile.name_bn : profile.name_en }}</td>
                            <td style="width: 18%"  class="text-left pl-3" ><b>{{ $t('globalTrans.mobile') }}</b></td>
                            <td style="width: 2%"  class="text-left" ><b>{{ ':' }}</b></td>
                            <td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(profile.mobile, { useGrouping:
                          false }) }}</td>
                          </tr>
                          <tr>
                            <td style="width: 18%"  class="text-left pl-3" ><b>{{ $t('portal.transactionId') }}</b></td>
                            <td style="width: 2%"  class="text-left" ><b>{{ ':'}}</b> </td>
                            <td style="width: 30%" class="text-left">{{ profile.transaction_no }}</td>
                            <td style="width: 18%"  class="text-left pl-3" ><b>{{ $t('globalTrans.date') }}</b></td>
                            <td style="width: 2%"  class="text-left" ><b>{{ ':' }}</b></td>
                            <td style="width: 30%" class="text-left">
                              {{ profile.created_at | dateFormat }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 18%"  class="text-left pl-3" ><b>{{ $t('globalTrans.payment') + ' ' + $t('globalTrans.amount')  }}</b></td>
                            <td style="width: 2%"  class="text-left" ><b>{{ ':'}}</b> </td>
                            <td style="width: 30%" class="text-left">{{ $n(profile.fee_amount, { useGrouping: false }) }} {{ $t('globalTrans.tk') }}</td>
                            <td style="width: 18%"  class="text-left pl-3" ><b></b></td>
                            <td style="width: 2%"  class="text-left" ><b></b></td>
                            <td style="width: 30%" class="text-left">
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-row>
                   </div>
                  <b-row>
                    <b-col xs="12" sm="12" md="12" lg="12">
                      <b-button variant="primary" @click="pdfExport" class="mb-2 float-right">
                          {{  $t('globalTrans.print') }}
                      </b-button>
                    </b-col>
                  </b-row>
                 </div>
                </b-col>
                  </b-overlay>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<style>
  #form fieldset {
    border: 1px solid #8f7c7c!important;
    float: left!important;
    margin-bottom: 25px!important;
    padding: 10px 0!important;
    width: 99.5%!important;
  }
  #form legend {
    background: #02813e none repeat scroll 0 0!important;
    border: 1px solid #e5e5e5!important;
    color: #fff!important;
    font-size: 13px!important;
    font-weight: 700!important;
    margin-bottom: 0!important;
    margin-left: 14px!important;
    padding: 4px 10px!important;
    width: 31%!important;
    border-radius: 0!important;
  }
  .alert .close {
    color: red !important;
  }
</style>
<script>
import RestApi, { authServiceBaseUrl, commonServiceBaseUrl, trainingElearningServiceBaseUrl } from '../../../../config/api_config'
import Common from '@/mixins/portal/common'
import ExportPdf from './export_pdf_Payment_details'
import { duplicateCertificateApiPaymentSuccess } from '../../api/routes'
import ListReportHead from '@/components/custom/ExternalListReportHead.vue'
import flatpickr from 'flatpickr'
import Vue from 'vue'
import VueHtmlToPaper from 'vue-html-to-paper'
Vue.use(VueHtmlToPaper)
const TWO_MINITES = 60 * 2
const today = new Date().toISOString().substr(0, 10)

export default {
  components: {
    ListReportHead
  },
  mixins: [Common],
  created () {
    this.paymentStatusUpdate()
    // this.duplicateCertificateFeeList()
    // this.getVenueCondition()
  },
  data () {
    return {
      current_date: today,
      isLoading: false,
      isLoadingData: false,
      load: false,
      loadModal: false,
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      commonServiceBaseUrl: commonServiceBaseUrl,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      profile: {},
      officeTypeList: [],
      officeList: [],
      designationList: [],
      booking: true,
      venuList: [],
      venueCondition: '',
      otp: '',
      reload: false,
      showMessage: false,
      otpCheck: '',
      venus: [],
      countShow: false,
      TWO_MINITES: TWO_MINITES,
      timer: 0,
      venuId: 0
    }
  },
  filters: {
    minutesAndSeconds (value) {
      var minutes = Math.floor(parseInt(value, 10) / 60)
      var seconds = parseInt(value, 10) - minutes * 60
      return `${minutes}:${seconds}`
    }
  },
  computed: {
    professionTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা' : 'MoA/Organization under MoA',
          text_en: 'MoA/Organization under MoA',
          text_bn: 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'অন্যান্য সরকারী সংস্থা' : 'Other Government Organization',
          text_en: 'Other Government Organization',
          text_bn: 'অন্যান্য সরকারী সংস্থা'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'প্রাইভেট প্রতিষ্ঠান' : 'Private Company',
          text_en: 'Private Company',
          text_bn: 'প্রাইভেট প্রতিষ্ঠান'
        }
      ]
    },
    subjectTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'সেমিনার' : 'Seminer',
          text_en: 'Seminer',
          text_bn: 'সেমিনার'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'প্রশিক্ষণ' : 'Training',
          text_en: 'Training',
          text_bn: 'প্রশিক্ষণ'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'কর্মশালা' : 'Workshop',
          text_en: 'Private Company',
          text_bn: 'Workshop'
        }
      ]
    },
    jobTypeList () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'ক্যাডার' : 'Cader',
          text_en: 'Cader',
          text_bn: 'ক্যাডার'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'নন-ক্যাডার' : 'Non-Cader',
          text_en: 'Non-Cader',
          text_bn: 'নন-ক্যাডার'
        }
      ]
    },
    orgList: function () {
      return this.$store.state.Portal.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    duplicateCertificateFeeList: function () {
      return this.$store.state.Portal.trainObj.duplicateCertificateFeeList.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          const n = obj.text_bn
          if (typeof n === 'string') {
            const num = n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
            return { value: obj.value, text: num }
          } else {
            return { value: obj.value, text: '' }
          }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    // duplicateCertificateFeeList: function () {
    //   return this.$store.state.Portal.trainObj.duplicateCertificateFeeList.map((obj, index) => {
    //     if (this.$i18n.locale === 'bn') {
    //       const n = parseInt(obj.text_bn)
    //       const num = n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
    //         return { value: obj.value, text: obj.text_bn }
    //     } else {
    //         return { value: obj.value, text: obj.text_en }
    //     }
    //   })
    // },
    govOrPvt: function () {
      const govOrPvt = [
        {
          value: 1,
          text: 'govt',
          text_en: 'govt',
          text_bn: 'সরকারী'
        },
        {
          value: 2,
          text: 'private',
          text_en: 'private',
          text_bn: 'ব্যক্তিগত'
        }
      ]
      const tmpList = govOrPvt.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn, text_en: obj.text_en, text_bn: obj.text_bn }
        } else {
            return { value: obj.value, text: obj.text_en, text_en: obj.text_en, text_bn: obj.text_bn }
        }
      })
      return tmpList
    },
    fiscalYearList: function () {
      return this.$store.state.Portal.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    venusDatas: function () {
      const tmpList = this.venus.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.venu_name_bn, text_en: obj.venu_name, text_bn: obj.venu_name_bn, org_id: obj.org_id }
        } else {
            return { value: obj.id, text: obj.venu_name, text_en: obj.venu_name, text_bn: obj.venu_name_bn, org_id: obj.org_id }
        }
      })
      return tmpList
    }
  },
  watch: {
    'formData.venue_id': function (newValue) {
      this.getVenueInfo(newValue)
    },
    'formData.fiscal_year_id': function (newValue) {
      this.getVenueCondition(newValue)
    },
    'formData.org_id': function (newVal, oldVal) {
      if (newVal !== 0) {
        this.designationList = this.getDesignationByOrg(newVal)
        this.officeTypeList = this.getOfficeTypeList(newVal)
      }
    },
    'formData.office_type_id': function (newValue) {
      if (newValue !== 0) {
        this.officeList = this.getOfficeList(newValue)
      }
    },
    'formData.office_id': function (newValue) {
      this.getVenusList(newValue)
    },
    'formData.gov_private': function (newValue) {
       this.getVenueInfo(this.formData.venue_id)
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
    setInterval(() => {
      this.timer -= 1
      if (this.timer === 0) {
        this.otpDelete()
      }
    }, 1000)
  },
  methods: {
    async paymentStatusUpdate () {
      if (this.$route.query.transId) {
        const transId = this.$route.query.transId
        await RestApi.getData(trainingElearningServiceBaseUrl, duplicateCertificateApiPaymentSuccess + '/' + transId).then(response => {
          if (response.success) {
            this.profile = response.result
            this.$toast.success({
              title: 'Success',
              message: response.message,
              color: '#D6E09B'
            })
          } else {
            this.profile = ''
            this.$toast.error({
              title: 'Error',
              message: response.message
            })
          }
        })
        // this.$router.push(this.$route)
      } else {
        // this.$router.push('/training/duplicate-certificate')
      }
    },
    getOrgName (id) {
      const trainingType = this.$store.state.Portal.commonObj.organizationProfileList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.abbreviation_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.abbreviation : ''
      }
    },
    getDesignation (id) {
      const trainingType = this.$store.state.Portal.commonObj.designationList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.abbreviation_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.abbreviation : ''
      }
    },
    getOrgAddress (id) {
      const trainingType = this.$store.state.Portal.commonObj.organizationProfileList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.address_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.address_en : ''
      }
    },
    getStatus (id) {
      const trainingType = this.statusList.find(job => job.value === parseInt(id))
        if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
          return trainingType !== undefined ? trainingType.text_en : ''
        }
    },
    getJobType (id) {
      const trainingType = this.$store.state.TrainingElearning.commonObj.jobTypeList.find(job => parseInt(job.value) === parseInt(id))
        if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
          return trainingType !== undefined ? trainingType.text_en : ''
        }
    },
    getApplicationSubject (id) {
      const trainingType = this.subjectTypeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text : ''
        } else {
          return trainingType !== undefined ? trainingType.text : ''
        }
    },
    getProfessionType (id) {
      const trainingType = this.professionTypeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text : ''
        } else {
          return trainingType !== undefined ? trainingType.text : ''
        }
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    getDesignationByOrg (orgId = null) {
      const desigList = this.$store.state.Portal.commonObj.designationList.filter(item => item.status === 0)
      if (desigList) {
          return desigList.filter(item => item.org_id === parseInt(orgId))
      }
      return desigList
    },
    getOfficeTypeList (orgId = null) {
      const officeTypeList = this.$store.state.Portal.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
      }
      return officeTypeList
    },
    getOfficeList (orgId = null) {
      const officeList = this.$store.state.Portal.commonObj.officeList.filter(item => item.status === 0)
      if (orgId) {
        return officeList.filter(office => office.office_type_id === orgId).map(obj => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
      }
      return officeList
    },
    checkDisable (field) {
      const mainData = []
      const data = mainData[field]
      if (data) {
          return true
      }
      return false
    },
    // async getFeeAmount () {
    //   const params = {
    //   }
    //   const result = await RestApi.getData(trainingElearningServiceBaseUrl, 'config/duplicate-certificate-fee/fee-list', params)
    //   if (!result.success) {
    //     this.formData.fee_amount = ''
    //   } else {
    //     const feeObj = result.data.data
    //     const fee = feeObj.find(item => item.status === 1 && item.effective_date >= this.current_date)
    //     this.formData.fee_amount = fee.fee_amount
    //   }
    // },
    // async getPreviewData (mobile) {
    //   const result = await RestApi.getData(trainingElearningServiceBaseUrl, 'external-user/duplicate-certificate-request/preview' + '/' + mobile)
    //   if (!result.success) {
    //     this.profile = ''
    //   } else {
    //     this.profile = result.data
    //   }
    // },
    async payment () {
      this.load = true
      let result = null
      result = await RestApi.postData(trainingElearningServiceBaseUrl, 'external-user/duplicate-certificate-request/payment', this.profile)
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        // this.$refs.mymodal.hide()
        this.showMessage = true
        window.location = result.url
        // this.$router.push('/training/certificate-request-preview')
        this.$refs.form.reset()
        this.load = false
        this.otp = ''
      } else {
          this.$refs.form.setErrors(result.errors)
      }
    },
    async loadUser (users) {
      this.userLoad = true
      const result = await RestApi.getData(authServiceBaseUrl, '/auth/portal/get-users', { user_ids: JSON.stringify(users) })
      this.userLoad = false
      if (result.success) {
       this.users = result.data
      }
    },
    async getVenusList () {
      this.loading = true
      const search = {
        org_id: this.formData.org_id,
        office_id: this.formData.office_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, '/config/master-venue-management/venue-list', search)
      this.loading = false
      if (result.success) {
        this.venus = result.data
      } else {
        this.venus = []
      }
    },
    details (id) {
      this.venuId = id
    },
    pdfExport () {
      const reportTitle = this.$t('globalTrans.payment') + ' ' + this.$t('globalTrans.receipt')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.profile, this)
    }
    // pdfExport () {
    //   this.$htmlToPaper('printData', this.pageStyle)
    //   // const reportTitle = this.$t('li_step.circular')
    //   // ExportPdf.exportPdfDetails(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/detail', 3, reportTitle, this, this.details)
    // }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  .reportTitle {
        font-weight: bold;
        border: 1px solid;
        padding: 8px 15px 8px 15px;
        border-radius: 11px;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
